<template>
  <a-modal v-model="modalVisible" title="报警记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal">
    <div class="main-container">
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="queryForms"  :label-col="{span:9}" :wrapper-col="{span:14}">
        <div class="card-content">
          <h3 style="font-weight: bold; color: #333;">报警添加</h3>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;">
            <span style="margin-left: 2%">报警信息</span>
          </h3>
          <a-form-model-item label="报警类型" prop="alarmtype">
            <a-input  v-model="queryForms.alarmtype" placeholder="请输入" style="width:100%;margin-top: -100px" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="来电号码" prop="callertel">
            <a-input v-model="queryForms.callertel" placeholder="请输入" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="报警人员" prop="alarmcaller">
            <a-input v-model="queryForms.alarmcaller" placeholder="请输入" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="接警人员" prop="processperson">
            <a-input v-model="queryForms.processperson" placeholder="请输入" read-only="read-only"></a-input>
          </a-form-model-item>
          <a-form-model-item label="报警时间" prop="calledtime">
            <a-date-picker
                v-model="queryForms.calledtime"
                style="width:100%"
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :disabled="true"
                v-decorator="[
                    'pushtime',
                    {
                      rules: [{ required: true, message: '请输入发布时间!' }]
                    }
                  ]"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"/>
          </a-form-model-item>
          <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
            <span style="margin-left: 2%">备注</span>
          </h3>
          <a-form-model-item prop="message" style="width: 48%;">
            <textarea v-model="queryForms.message" style="width:300%;height:150px;margin-left: 35%" placeholder="请输入" disabled></textarea>
          </a-form-model-item>
          <div class="showbutton" style="text-align: right">
            <a-button @click="close">关闭</a-button>
          </div>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import moment from "moment";
import callCenter from "C/modals/callCenter";
export default {
  name: "otherBack",
  props: {
    OtherData:{
      default:null
    },
    visible: {
      default: false
    },
  },
  data() {
    return {
      modalVisible:false,
      moment,
      pages: '',
      uesrname:'',
      alarmtype:'',
      queryForms:{
        alarmtype:'',
        alarmcaller:'',
        called:'',
        calledtime:'',
        message:'',
        processperson:'',
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        console.log(this.OtherData)
        this.queryForms=this.OtherData
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
    },
    close(){
      this.modalVisible = false
    },
  },
}
</script>
<style scoped>

</style>